<template>
  <span>
    <input
      class="checkbox-conciliacao"
      type="checkbox"
      :value="value"
      @change="updateData()"
      :checked="checked"
      @click="(event) => clickPropagation(event)"
    />
    <label class="ml-2">{{ label }}</label>
  </span>
</template>

<script>
export default {
  props: {
    label: String,
    value: String,
    arrayValues: Array,
    readonly: Boolean
  },

  computed: {
    checked() {
      return this.arrayValuesLocal.indexOf(this.value) !== -1;
    },

    arrayValuesLocal: {
      get() {
        return this.arrayValues;
      },
      set(newValue) {
        this.$emit('update:arrayValues', newValue);
      }
    }
  },

  methods: {
    updateData() {
      if (this.readonly) {
        return;
      } else if (!this.checked) {
        this.arrayValuesLocal.push(this.value);
      } else {
        this.arrayValuesLocal = this.arrayValuesLocal.filter(
          (element) => element !== this.value
        );
      }

      this.$emit('change', this.arrayValuesLocal);
    },

    clickPropagation(event) {
      if (this.readonly) {
        event.preventDefault();
      }

      this.$emit('click');
    }
  }
};
</script>

<style>
.checkbox-conciliacao {
  accent-color: #550899;
}
</style>
